import './registration.css';
import React from 'react';
import { useState } from 'react';

const Registration = () => {

    const [formData, setFormData] = useState({
        sponsorName: '',
        contactFirstName: '',
        contactLastName: '',
        phone: '',
        address: '',
        email: '',
        team1: ['', '', '', ''],
        team2: ['', '', '', ''],
        team3: ['', '', '', ''],
        team4: ['', '', '', ''],
        singlePlayer: '',
        nonGolfer: ''
    });

    // Handle team input change
    const handleTeamChange = (team, index, e) => {
        const updatedTeam = [...formData[team]];
        updatedTeam[index] = e.target.value;
        setFormData({
            ...formData,
            [team]: updatedTeam
        });
    };

    // Handle input changes for single fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    
        // Send the formData to the server
        fetch('http://localhost:3001/api/registration', {  // Replace '/api/registration' with your actual backend API route
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle success response (e.g., redirect user or show confirmation)
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle error response (e.g., display error message to user)
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="patronDetails">
                    <input
                    type="text"
                    name="sponsorName"
                    placeholder="Business or Sponsor Name"
                    value={formData.sponsorName}
                    onChange={handleChange}
                    />

                <input
                    type="text"
                    name="contactFirstName"
                    placeholder="First Name"
                    value={formData.contactFirstName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="contactLastName"
                    placeholder="Last Name"
                    value={formData.contactLastName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                </div>

                <h4>Team 1</h4>
                {formData.team1.map((player, index) => (
                    <input
                        key={index}
                        type="text"
                        name={`team1-player${index}`}
                        value={formData.team1[index]}
                        onChange={(e) => handleTeamChange('team1', index, e)}

                    />
                ))}

                <h4>Team 2</h4>
                {formData.team2.map((player, index) => (
                    <input
                        key={index}
                        type="text"
                        name={`team2-player${index}`}
                        value={formData.team2[index]}
                        onChange={(e) => handleTeamChange('team2', index, e)}

                    />
                ))}

                <h4>Team 3</h4>
                {formData.team3.map((player, index) => (
                    <input
                        key={index}
                        type="text"
                        name={`team3-player${index}`}
                        value={formData.team3[index]}
                        onChange={(e) => handleTeamChange('team3', index, e)}

                    />
                ))}

                <h4>Team 4</h4>
                {formData.team4.map((player, index) => (
                    <input
                        key={index}
                        type="text"
                        name={`${index}`}
                        value={formData.team4[index]}
                        onChange={(e) => handleTeamChange('team4', index, e)}

                    />
                ))}
                        <h2>Single Player</h2>
                        <input
                            type="text"
                            name="singlePlayer"
                            placeholder="Single Player"
                            value={formData.singlePlayer}
                            onChange={handleChange}
                            required
                        />

                        <h2>Non-Golfer</h2>
                        <input
                            type="text"
                            name="nonGolfer"
                            placeholder="Non-Golfer"
                            value={formData.nonGolfer}
                            onChange={handleChange}
                            required
                        />
                
                <button type="submit" className="submit">Proceed To Purchase</button>
            </form>
        </div>
    );
};

export default Registration;