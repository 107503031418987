import './home.css';
import React from 'react';
//import Registration from './Registration';
import EventbriteWidget from './EventBrite';

const regTypes = [
    { id: 1, title: "Title Sponsor", detail1: "3 Foursomes Friday & Saturday", detail2: "8 Non-Golfer Tickets for Friday & Saturday Dinner & Entertainment", detail3: "Reserved Banquet Seating for 20", detail4: "One Premium Sponsor Hole with Signage", detail5: "Company Name & Logo on Event Sponsorship Signage, MFSB Facebook Page & Registration Website", detail6: "Remarks at the Welcome Program & Banquet", cost: 25000, costText: "25,000"},
    { id: 2, title: "Golf Gift Sponsor", detail1: "2 Foursomes Friday & Saturday", detail2: "4 Non-Golfer Tickets for Friday & Saturday Dinner & Entertainment", detail3: "Reserved Banquet Seating for 12", detail4: "One Premium Sponsor Hole with Signage", detail5: "Company Name & Logo on Event Sponsorship Signage, MFSB Facebook Page & Registration Website", detail6: "Co-Sponsor Remarks at the Welcome Program & Banquet", cost: 10000, costText: "10,000"},
    { id: 3, title: "Lunch/Dinner Sponsor", detail1: "1 Foursomes Friday & Saturday", detail2: "2 Non-Golfer Tickets for Friday & Saturday Dinner & Entertainment", detail3: "Reserved Banquet Seating for 6", detail4: "One Hole Sponsorship with Signage", detail5: "Company Name & Logo on Event Sponsorship Signage, MFSB Facebook Page & Registration Website", cost: 5000, costText: "5,000"},
    { id: 4, title: "Beverage Cart", detail1: "Company Name & Logo on Sponsorship Signage Displayed at Friday & Saturday Banquet", detail2: "Reserved Banquet Seating for 4", detail3: "Company Name & Logo on Event Sponsorship Signage, MFSB Facebook Page & Registration Website", cost: 2500, costText: "2,500"},
    { id: 5, title: "Weekend Gold Golf Package", detail1: "Prizes for Top Teams, Men, Women & Mixed", detail2: "Continental Breakfast, Boxed Lunches", detail3:"Raffle Auction, Meet & Greet", detail4:"Friday Dinner & Entertainment",detail5:"Saturday Awards Banquet", cost:400, costText: "400"},
    { id: 6, title: "Weekend Package for Non-Golfer", detail1: "Raffle Auction", detail2: "Meet & Greet", detail3:"Friday Dinner & Entertainment", detail4:"Saturday Awards Banquet", cost: 200, costText: "200"}, 
    { id: 7, title: "Hole Sponsorship", detail1: "Company Name & Logo on Signage Near a Hole", cost: 300, costText: "300"}
];
const scrollToSection = () => {
    const target = document.getElementById("target");
    if (target) {
    target.scrollIntoView({ behavior: 'smooth' });
    }
};

const Home = () => {

    return (
        <div>
            <div className='header'>
                <img src="/mfsb.jpg" alt="Michigan Florida Snowbirds" className="header-image" />
                <div className="regBlurb">
                    <div className="head-logo">
                        <img src="/MFSB logo.png" alt="MFSB Logo" className="header-logo" />
                    </div>
                    <div className="regInfo">                    
                        <p className="title">REGISTER TODAY!</p>
                        <p className="eventTitle">Michigan & Florida</p>
                        <p className="description">Snowbirds Golf Weekend</p>
                        <p className="description">January 30 - February 1, 2025</p>
                    </div>
                </div>
                <button className="register-button" onClick={scrollToSection}>Register Here</button>
            </div>

            <div className="registration" id="target">
                <div className="registration-cards">
                    {regTypes.map((regType) => (
                        <div className="registration-card" key={regType.id}>
                            <h1 className="card-header">{regType.title}</h1>
                            <p className="card-details">{regType.detail1}</p>
                            <p className="card-details">{regType.detail2}</p>
                            <p className="card-details">{regType.detail3}</p>
                            <p className="card-details">{regType.detail4}</p>
                            <p className="card-details">{regType.detail5}</p>
                            <p className="card-details">{regType.detail6}</p>
                            <div className="seperator"></div>
                            <p className="cost">${regType.costText}</p>

                            <EventbriteWidget />

                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Home;


