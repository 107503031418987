import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Home from "./Home"
import Registration from "./Registration"
import React from 'react' 

function App() {
  return (
    <div>
      <div className="nav-bar">
        <Link className="link" to="/">HOME</Link>
        <Link className="link">SPONSOR INFO</Link>
        <Link className="link">ABOUT</Link>
        <Link className="link" to="/">REGISTER</Link>
        <Link className="link" to="/">GALLERY</Link>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register/:regType" element={<Registration />} />
      </Routes>
    </div>
  );
}

export default App;
