import React, { useEffect } from 'react';

const EventbriteWidget = () => {
  useEffect(() => {
    // Load Eventbrite widget script
    const script = document.createElement('script');
    script.src = 'https://www.eventbrite.com/static/widgets/eb_widgets.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Create the Eventbrite widget after the script loads
      window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '1041285150917', // Replace with your Event ID
        modal: true,
        modalTriggerElementId: 'eventbrite-widget-modal-trigger-1041285150917',
        onOrderComplete: () => {
          console.log('Order complete!');
        },
      });
    };

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Noscript content for added SEO */}
      <noscript>
        <a
          href="https://www.eventbrite.com/e/michigan-florida-test-event-tickets-1041285150917"
          rel="noopener noreferrer"
          target="_blank"
        >
          Buy Tickets on Eventbrite
        </a>
      </noscript>
      {/* Button to trigger the modal */}
      <button id="eventbrite-widget-modal-trigger-1041285150917" className="purchase" type="button">
        Buy Tickets
      </button>
    </div>
  );
};

export default EventbriteWidget;